import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const LogoIcon = () => {
  const styles = useStyles();

  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="184" height="34" viewBox="0 0 184 34" fill="none">
        <path
            d="M43.8984 10.1471H51.1587C52.2153 10.1471 53.1328 10.261 53.9078 10.4903C54.6829 10.7197 55.3267 11.0503 55.8376 11.4821C56.3485 11.9139 56.7281 12.4407 56.978 13.0639C57.228 13.6871 57.3529 14.3894 57.3529 15.1692C57.3529 15.9031 57.2295 16.5817 56.9828 17.2049C56.736 17.8281 56.358 18.3659 55.8502 18.8215C55.3425 19.277 54.7003 19.6314 53.9252 19.8876C53.1502 20.1438 52.2328 20.2704 51.1761 20.2704L46.6918 20.2609V23.9179H43.8984V10.1471ZM51.2299 17.8487C51.7582 17.8487 52.2217 17.7854 52.6171 17.6589C53.0141 17.5323 53.3447 17.3536 53.612 17.1211C53.8794 16.8886 54.0787 16.607 54.2115 16.2748C54.3444 15.9427 54.4093 15.5741 54.4093 15.1692C54.4093 14.353 54.1435 13.7171 53.612 13.2616C53.0806 12.806 52.2865 12.5783 51.2283 12.5783H46.6902V17.8471H51.2299V17.8487Z"
            fill={styles.main}/>
        <path
            d="M60.0752 13.5131H62.674V18.5889C62.674 19.0714 62.7262 19.5095 62.8322 19.9065C62.9382 20.3036 63.1185 20.6421 63.3748 20.9236C63.631 21.2052 63.9711 21.425 64.3966 21.5801C64.8221 21.7351 65.352 21.8142 65.9862 21.8142C66.2852 21.8142 66.5905 21.7825 66.8989 21.7177C67.2074 21.6528 67.5032 21.5642 67.7847 21.4488C68.0663 21.3349 68.3288 21.1973 68.574 21.0391C68.8176 20.8809 69.028 20.7069 69.2036 20.5187V13.5131H71.8119V23.9195H69.2036V23.0289C68.5629 23.4117 67.9207 23.6838 67.2738 23.8483C66.6269 24.0128 66.0131 24.0951 65.4326 24.0951C64.4804 24.0951 63.6658 23.9812 62.9872 23.7518C62.3087 23.5225 61.7534 23.1887 61.3216 22.7521C60.8898 22.314 60.5735 21.7778 60.3742 21.1403C60.1748 20.5029 60.0752 19.7768 60.0752 18.9591V13.5131Z"
            fill={styles.main}/>
        <path
            d="M75.1255 13.5225H77.7338V15.0204C78.3096 14.5918 78.917 14.2216 79.5576 13.91C80.1982 13.5984 80.8357 13.3612 81.4699 13.1967L82.2371 15.5851C81.2327 15.6848 80.35 15.9094 79.5892 16.2589C78.8284 16.6085 78.2099 17.1004 77.7338 17.7347V23.9194H75.1255V13.5225Z"
            fill={styles.main}/>
        <path
            d="M83.6812 18.6853C83.6812 17.9799 83.8251 17.3061 84.113 16.6639C84.4009 16.0201 84.8153 15.4522 85.3547 14.9587C85.8956 14.4652 86.5568 14.0713 87.3414 13.7787C88.1259 13.4845 89.0164 13.3374 90.0161 13.3374C91.0095 13.3374 91.9032 13.4861 92.6988 13.7819C93.4944 14.0792 94.1698 14.4858 94.725 15.003C95.2802 15.5202 95.7057 16.1276 96.0031 16.8268C96.2989 17.5259 96.4476 18.2741 96.4476 19.0745C96.4476 19.1741 96.446 19.2817 96.4428 19.3956C96.4397 19.5095 96.4318 19.6138 96.4207 19.7088H86.5869C86.6992 20.0204 86.8795 20.3051 87.1294 20.5629C87.3793 20.8223 87.6925 21.0453 88.0674 21.232C88.4438 21.4202 88.8773 21.5657 89.3708 21.6686C89.8643 21.7714 90.41 21.822 91.0095 21.822C91.609 21.822 92.2053 21.7587 92.7984 21.6322C93.3916 21.5056 93.9579 21.3459 94.4988 21.1513L95.3799 23.1428C95.0398 23.3009 94.6934 23.4417 94.3438 23.5619C93.9943 23.6821 93.6304 23.7818 93.2508 23.8609C92.8712 23.94 92.4758 24.0001 92.0613 24.0412C91.6469 24.0823 91.2104 24.1029 90.7532 24.1029C89.5843 24.1029 88.5593 23.9621 87.6783 23.6806C86.7972 23.399 86.0601 23.0146 85.467 22.5259C84.8738 22.0387 84.4277 21.4661 84.1272 20.8081C83.8298 20.1516 83.6812 19.443 83.6812 18.6853ZM93.6811 17.6714C93.5877 17.3836 93.4422 17.1147 93.2445 16.8647C93.0484 16.6148 92.8032 16.3981 92.509 16.2131C92.2148 16.028 91.8747 15.8825 91.4872 15.7765C91.0996 15.6705 90.6741 15.6183 90.2091 15.6183C89.7156 15.6183 89.2664 15.6737 88.8614 15.786C88.4565 15.8983 88.1006 16.047 87.7953 16.2352C87.49 16.4234 87.2354 16.6401 87.0329 16.8869C86.8304 17.1336 86.6818 17.3946 86.5884 17.6714H93.6811Z"
            fill={styles.main}/>
        <path
            d="M104.73 16.9503C104.73 15.9696 104.915 15.0538 105.285 14.206C105.655 13.3566 106.192 12.6195 106.894 11.9947C107.596 11.3699 108.453 10.8779 109.467 10.5189C110.481 10.1614 111.628 9.98108 112.908 9.98108C114.029 9.98108 115.059 10.095 115.995 10.3196C116.932 10.5458 117.803 10.8906 118.608 11.3556L117.322 13.4704C117.082 13.3123 116.804 13.1715 116.49 13.0481C116.176 12.9247 115.842 12.8203 115.486 12.7349C115.13 12.6495 114.763 12.5831 114.385 12.5372C114.005 12.4898 113.632 12.466 113.262 12.466C112.368 12.466 111.573 12.5768 110.874 12.7966C110.174 13.0165 109.586 13.3249 109.107 13.722C108.627 14.119 108.264 14.5919 108.014 15.1408C107.764 15.6897 107.639 16.2939 107.639 16.9519C107.639 17.6337 107.769 18.2584 108.026 18.8279C108.284 19.3973 108.659 19.8892 109.149 20.3037C109.64 20.7181 110.239 21.0408 110.946 21.2733C111.653 21.5058 112.454 21.6213 113.347 21.6213C113.752 21.6213 114.156 21.5912 114.554 21.5327C114.954 21.4742 115.34 21.3935 115.714 21.2907C116.087 21.1879 116.446 21.0645 116.793 20.9206C117.139 20.7766 117.462 20.62 117.762 20.4492L119.048 22.564C118.297 23.0464 117.427 23.4229 116.44 23.6966C115.453 23.9702 114.404 24.1062 113.294 24.1062C111.878 24.1062 110.635 23.9227 109.567 23.5558C108.498 23.1888 107.606 22.6842 106.889 22.0404C106.173 21.3967 105.633 20.639 105.273 19.7674C104.91 18.8927 104.73 17.9547 104.73 16.9503Z"
            fill={styles.main}/>
        <path
            d="M121.797 9.31018H124.406V14.3149C124.952 14.0159 125.562 13.7771 126.234 13.6015C126.907 13.4259 127.614 13.3373 128.354 13.3373C129.211 13.3373 129.983 13.4639 130.666 13.717C131.351 13.9701 131.93 14.3323 132.406 14.8052C132.882 15.2782 133.248 15.8524 133.503 16.5278C133.759 17.2032 133.885 17.964 133.885 18.8103V23.9209H131.286V18.9162C131.286 18.4227 131.207 17.9719 131.049 17.5638C130.891 17.1557 130.659 16.8046 130.353 16.5104C130.048 16.2162 129.676 15.99 129.238 15.827C128.8 15.6657 128.305 15.585 127.753 15.585C127.088 15.585 126.472 15.691 125.902 15.903C125.333 16.1149 124.833 16.3822 124.404 16.7049V23.9209H121.796V9.31018H121.797Z"
            fill={styles.main}/>
        <path
            d="M136.177 18.7201C136.177 17.9735 136.329 17.2744 136.631 16.6227C136.933 15.971 137.365 15.4016 137.926 14.9128C138.488 14.4256 139.165 14.0413 139.962 13.7581C140.758 13.4766 141.653 13.3358 142.645 13.3358C143.638 13.3358 144.532 13.4766 145.327 13.7581C146.123 14.0397 146.801 14.4256 147.363 14.9128C147.925 15.4 148.356 15.9694 148.658 16.6227C148.961 17.2744 149.112 17.9735 149.112 18.7201C149.112 19.4667 148.961 20.1659 148.658 20.8176C148.356 21.4692 147.925 22.0403 147.363 22.5274C146.801 23.0146 146.123 23.4006 145.327 23.6821C144.532 23.9637 143.636 24.1045 142.645 24.1045C141.651 24.1045 140.758 23.9637 139.962 23.6821C139.166 23.4006 138.488 23.0162 137.926 22.5274C137.365 22.0403 136.933 21.4708 136.631 20.8176C136.329 20.1659 136.177 19.4667 136.177 18.7201ZM138.918 18.7201C138.918 19.1377 139.002 19.5316 139.169 19.9049C139.337 20.2782 139.581 20.6072 139.9 20.8919C140.22 21.1766 140.61 21.4028 141.072 21.5705C141.534 21.7381 142.058 21.822 142.645 21.822C143.231 21.822 143.757 21.7381 144.217 21.5705C144.679 21.4028 145.068 21.1766 145.389 20.8919C145.708 20.6072 145.954 20.2782 146.12 19.9049C146.287 19.5316 146.371 19.1377 146.371 18.7201C146.371 18.3025 146.287 17.9071 146.12 17.5306C145.952 17.1542 145.708 16.8252 145.389 16.5389C145.069 16.2542 144.679 16.028 144.217 15.8603C143.755 15.6926 143.231 15.6088 142.645 15.6088C142.058 15.6088 141.533 15.6926 141.072 15.8603C140.61 16.028 140.22 16.2542 139.9 16.5389C139.581 16.8236 139.336 17.1542 139.169 17.5306C139.002 17.9071 138.918 18.3041 138.918 18.7201Z"
            fill={styles.main}/>
        <path
            d="M153.121 12.1035C152.861 12.1035 152.629 12.0671 152.42 11.9928C152.211 11.92 152.034 11.8188 151.887 11.6939C151.74 11.5673 151.627 11.4202 151.548 11.2525C151.469 11.0849 151.43 10.9077 151.43 10.7195C151.43 10.5249 151.469 10.3446 151.548 10.1769C151.627 10.0093 151.741 9.86375 151.887 9.74038C152.034 9.617 152.211 9.51893 152.42 9.44459C152.629 9.37183 152.861 9.33386 153.121 9.33386C153.385 9.33386 153.622 9.37024 153.829 9.44459C154.038 9.51735 154.215 9.617 154.362 9.74038C154.509 9.86375 154.622 10.0093 154.701 10.1769C154.78 10.3446 154.819 10.5249 154.819 10.7195C154.819 10.9077 154.78 11.0849 154.701 11.2525C154.622 11.4202 154.508 11.5673 154.362 11.6939C154.215 11.8204 154.038 11.92 153.829 11.9928C153.622 12.0671 153.385 12.1035 153.121 12.1035ZM151.817 13.5224H154.426V23.9193H151.817V13.5224Z"
            fill={styles.main}/>
        <path
            d="M157.139 18.747C157.139 17.983 157.278 17.2712 157.558 16.6101C157.836 15.9489 158.246 15.3763 158.782 14.8923C159.32 14.4083 159.978 14.0271 160.756 13.7518C161.534 13.475 162.423 13.3374 163.421 13.3374C163.856 13.3374 164.293 13.3643 164.734 13.4165C165.176 13.4687 165.6 13.5446 166.008 13.6411C166.416 13.7376 166.799 13.8531 167.158 13.9843C167.517 14.1172 167.827 14.2612 168.091 14.4209L166.884 16.3855C166.314 16.1213 165.775 15.9268 165.267 15.8034C164.76 15.68 164.244 15.6183 163.72 15.6183C163.121 15.6183 162.583 15.6958 162.109 15.8524C161.632 16.0074 161.229 16.2257 160.897 16.5041C160.565 16.7825 160.312 17.1115 160.139 17.4911C159.965 17.8707 159.88 18.282 159.88 18.7296C159.88 19.1757 159.972 19.5885 160.157 19.9682C160.342 20.3478 160.603 20.6736 160.941 20.9457C161.28 21.2193 161.688 21.4329 162.166 21.5895C162.645 21.7445 163.178 21.8236 163.765 21.8236C164.412 21.8236 165.021 21.7318 165.593 21.5499C166.166 21.368 166.699 21.1244 167.192 20.8192L168.399 22.7837C168.088 22.9719 167.751 23.1459 167.385 23.3073C167.022 23.4686 166.632 23.6078 166.218 23.7264C165.804 23.8435 165.369 23.9368 164.915 24.0032C164.459 24.0713 163.988 24.1045 163.501 24.1045C162.507 24.1045 161.618 23.97 160.831 23.6995C160.043 23.4291 159.377 23.0558 158.831 22.5765C158.285 22.0972 157.866 21.5325 157.575 20.8808C157.284 20.2276 157.139 19.5174 157.139 18.747Z"
            fill={styles.main}/>
        <path
            d="M170.716 18.6853C170.716 17.9799 170.86 17.3061 171.148 16.6639C171.436 16.0201 171.85 15.4522 172.39 14.9587C172.931 14.4652 173.592 14.0713 174.377 13.7787C175.161 13.4845 176.052 13.3374 177.051 13.3374C178.045 13.3374 178.938 13.4861 179.734 13.7819C180.53 14.0792 181.205 14.4858 181.76 15.003C182.315 15.5202 182.741 16.1276 183.038 16.8268C183.334 17.5259 183.483 18.2741 183.483 19.0745C183.483 19.1741 183.481 19.2817 183.478 19.3956C183.475 19.5095 183.467 19.6138 183.456 19.7088H173.622C173.734 20.0204 173.915 20.3051 174.165 20.5629C174.414 20.8223 174.726 21.0453 175.103 21.232C175.479 21.4202 175.914 21.5657 176.406 21.6686C176.899 21.7714 177.445 21.822 178.045 21.822C178.644 21.822 179.24 21.7587 179.834 21.6322C180.427 21.5056 180.993 21.3459 181.534 21.1513L182.415 23.1428C182.075 23.3009 181.729 23.4417 181.379 23.5619C181.029 23.6821 180.666 23.7818 180.286 23.8609C179.906 23.94 179.511 24.0001 179.096 24.0412C178.682 24.0823 178.245 24.1029 177.788 24.1029C176.619 24.1029 175.594 23.9621 174.713 23.6806C173.832 23.399 173.095 23.0146 172.502 22.5259C171.909 22.0387 171.463 21.4661 171.162 20.8081C170.867 20.1516 170.716 19.443 170.716 18.6853ZM180.718 17.6714C180.624 17.3836 180.479 17.1147 180.281 16.8647C180.085 16.6148 179.838 16.3981 179.546 16.2131C179.251 16.028 178.911 15.8825 178.524 15.7765C178.136 15.6705 177.711 15.6183 177.246 15.6183C176.752 15.6183 176.303 15.6737 175.898 15.786C175.493 15.8983 175.137 16.047 174.832 16.2352C174.527 16.4234 174.272 16.6401 174.07 16.8869C173.867 17.1336 173.718 17.3946 173.625 17.6714H180.718Z"
            fill={styles.main}/>
        <path
            d="M28.3689 0H5.63108C2.52133 0 0 2.52133 0 5.63107V28.3689C0 31.4787 2.52133 34 5.63108 34H28.3689C31.4787 34 34 31.4787 34 28.3689V5.63107C33.9984 2.52133 31.4787 0 28.3689 0ZM3.59693 15.9726L9.89393 9.67565C10.3321 9.2375 10.9268 8.99074 11.5469 8.99074H15.7575C16.3776 8.99074 16.9723 9.2375 17.4105 9.67565L23.7075 15.9726L18.5288 16.0312L13.6506 11.1135L8.77246 16.0312L3.59693 15.9726ZM24.1045 24.3228C23.6663 24.7609 23.0716 25.0077 22.4516 25.0077H18.2409C17.6208 25.0077 17.0261 24.7609 16.588 24.3228L10.291 18.0258L15.4696 17.9672L20.3478 22.885L25.226 17.9672L30.4047 18.0258L24.1045 24.3228Z"
            fill={styles.main}/>
      </svg>
  );
};
