import { Header } from "../Header/";
import { Footer } from "../Footer/";
import FooterBg from "@assets/images/main/footer-bg.svg"

import "./layout.sass";

export const Layout = ({ children }) => {
  return (
    <div className={`base-wrap`}>
      <div className="base-wrap__header wrapper">
        <Header />
      </div>

      <div className="base-wrap__content">{children}</div>

      <div className="base-wrap__footer wrapper">{<>
          <Footer/>
          <img className='base-wrap__footer-bg' src={FooterBg}/>
      </>}</div>
    </div>
  );
};
