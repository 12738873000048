import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Layout } from "@components/layout/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "@helpers/translate";
import { ALERT } from "@constants";
import { useDispatch } from "react-redux";

import Backround from "@assets/images/main/background.svg";
import ConditionFirst from "@assets/images/main/conditions-first-bg.svg";
import ConditionSecond from "@assets/images/main/conditions-second-bg.svg";
import ConditionFifth from "@assets/images/main/conditions-fifth-bg.svg";
import ConditionSixth from "@assets/images/main/conditions-sixth-bg.svg";
import WhyFirstImg from '@assets/images/main/why-first.svg';
import WhyFirstImgBg from '@assets/images/main/why-first-bg.svg';
import WhySecondImg from '@assets/images/main/why-second-bg.svg';
import WhyThirdImg from '@assets/images/main/why-third-bg.svg';
import WhyFourthImg from '@assets/images/main/why-fourth.svg';
import WhyFourthBg from "@assets/images/main/why-fourth-bg.svg";
import WhyFifthImg from '@assets/images/main/why-fifth-bg.svg';
import WhyFifthBg from '@assets/images/main/why-fifth-gradient-bg.svg';
import OfferMobile from "@assets/images/main/offer-img-mobile.svg"

import "./index.sass";
import { Helmet } from "react-helmet";

export const Main = () => {
  const { __ } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const isBanned = sessionStorage.getItem("isBanned");

    if (isBanned) {
      dispatch({ type: ALERT.ALERT_ERROR, payload: __("common.banned") });
      sessionStorage.removeItem("isBanned");
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>
          {__("seo.main_title")} {__("seo.divider")} {__("seo.title")}
        </title>
      </Helmet>

      <section className="hero">
          <img className="hero__main-bg" src={Backround} />
        <div className="wrapper">
          <div className="hero__wrapper">
            <div className="hero__content">
              <h1 className="hero__title">{__("main.hero.title")}</h1>
              <p className="hero__subtitle">{__("main.hero.subtitle")}</p>
              <Link
                to="/auth/signup"
                className="hero__btn btn btn--large btn--orange"
              >
                {__("main.hero.button")}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="conditions">
        <div className="wrapper">
          <header className="conditions__header">
            <h2 className="conditions__title section-title">
              {__("main.conditions.title")}
            </h2>
          </header>

          <Grid
            rowSpacing={{ lg: "1rem", xs: "20px" }}
            columnSpacing="1rem"
            container
          >
            <Grid item b1440={3} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">$5</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-1")}
                </p>
                <img className='condition-card__bg' src={ConditionFirst}/>
              </article>
            </Grid>
            <Grid item b1440={3} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">$1</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-2")}
                </p>
                <img className='condition-card__bg' src={ConditionSecond}/>
              </article>
            </Grid>
            <Grid item b1440={3} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">0%</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-5")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={3} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">50+</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-4")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={7.5} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">$10 000</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-3")}
                </p>
                <img className='condition-card__bg' src={ConditionFifth}/>
              </article>
            </Grid>
            <Grid item b1440={4.5} xs={6} sx={{height: '240px'}}>
              <article className="condition-card">
                <h3 className="condition-card__title">100+</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-6")}
                </p>
                <img className='condition-card__bg' src={ConditionSixth}/>
              </article>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="why">
        <div className="wrapper">
          <header className="why__header">
            <h2 className="why__title section-title">{__("main.why.title")}</h2>
          </header>

          <Grid
            rowSpacing={{ b1440: "30px", lg: "1rem", xs: "20px" }}
            columnSpacing="1rem"
            container
          >
            <Grid item lg={12} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap why-card__wrap-end why-card__wrap-end-first">

                  <h3 className="why-card__title">
                    {__("main.why.i-1_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-1_text")}</p>
                </div>

                <img className="why-card__img-first" src={WhyFirstImg}/>
                <img className="why-card__pic" src={WhyFirstImgBg}/>
              </article>
            </Grid>
            <Grid item lg={7} xs={12}>
              <article className="why-card why-card__second why-card__wrap-end">
                <div className="why-card__wrap">

                  <h3 className="why-card__title">
                    {__("main.why.i-2_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-2_text")}</p>
                </div>
                <img className="why-card__img-bg why-card__img-bg-second" src={WhySecondImg}/>
              </article>
            </Grid>
            <Grid item lg={5} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap">

                  <h3 className="why-card__title why-card__title-smaller">
                    {__("main.why.i-3_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-3_text")}</p>
                </div>
                <img className="why-card__img-bg why-card__img-bg-third" src={WhyThirdImg}/>
              </article>
            </Grid>
            <Grid item lg={5} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap why-card__wrap-end">

                  <h3 className="why-card__title why-card__title-smaller">
                    {__("main.why.i-4_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-4_text")}</p>
                </div>

                <img className="why-card__img-bg why-card__img-bg-fourth" src={WhyFourthImg}/>
                <img className="why-card__img-bg" src={WhyFourthBg}/>
              </article>
            </Grid>
            <Grid item lg={7} xs={12}>
              <article className="why-card why-card--big">

                <div className="why-card__wrap why-card__wrap-last">
                  <h3 className="why-card__title why-card__title-smaller">
                    {__("main.why.i-5_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-5_text")}</p>
                </div>
                <img className="why-card__img-bg why-card__img-bg-fifth" src={WhyFifthImg}/>
                <img className="why-card__img-bg" src={WhyFifthBg}/>
              </article>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="offer">
        <div className="wrapper">
          <div className="offer__wrapper">
            <div className="offer__left">
              <h2 className="offer__title">{__("main.offer.title")}</h2>
              <p className="offer__text">{__("main.offer.text")}</p>
              <Link
                to="/auth/signup"
                className="offer__btn btn btn--middle btn--orange"
              >
                {__("main.offer.start")}
              </Link>
            </div>

            <img className="offer__pic" src={OfferMobile}/>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Main;
