import config from "../config";
export const main = {
  en: {
    "main.hero.title":
      "The right place for online trading on financial markets",
    "main.hero.subtitle":
      "The most convenient interface Get access to trade over 100 global trading assets",
    "main.hero.button": "Start in one click",
    "main.conditions.title": "Place your trades on best conditions",
    "main.conditions.i-1": "Minimum investment amount",
    "main.conditions.i-2": "Minimum trade amount",
    "main.conditions.i-3": "Virtual money on your Demo account",
    "main.conditions.i-4": "Payment methods",
    "main.conditions.i-5": "No commission on deposit and withdrawal",
    "main.conditions.i-6": "Assets for trading",
    "main.why.title": "Why choose us?",
    "main.why.i-1_title": "Flexible Trading",
    "main.why.i-1_text":
      "Latest trends: quick and digital trading, express trades, mt5 forex, pending orders, trades copying. Payouts up to 218%.",
    "main.why.i-2_title": "Diverse Trading Instruments",
    "main.why.i-2_text":
      "Assets suitable for any trader: currency, commodities, stocks, cryptocurrencies.",
    "main.why.i-3_title": "Demo Account",
    "main.why.i-3_text":
      "Try all platform benefits on the Demo account using virtual money. No investment needed, no risks involved.",
    "main.why.i-4_title": "Easy Deposits And Withdrawals",
    "main.why.i-4_text":
      "Use cashback and other advantages for a more comfortable trading experience with minimal risks.",
    "main.why.i-5_title": "Trading Advantages",
    "main.why.i-5_text":
      "Use cashback and other advantages for a more comfortable trading experience with minimal risks.",
    "main.offer.title": "Test Your Luck!",
    "main.offer.text": `Enter your email address and get a special offer from ${config.default_title}. If the luck is on your side, you will receive a free gift!`,
    "main.offer.start": "Start in one click",

    "main.about_us": "About Us",
    "main.privacy_policy": "Privacy Policy",
    "main.cookie_policy": "Cookie Policy",
    "main.terms_of_use": "Terms of Use",
    "main.help_center": "Help Center",
    "main.faq": "FAQs",
    "main.aml_policy": "KYC/AML Policy",

    "main.footer.t1": "All Rights Reserved.",
    "main.footer.t2": "General information",
    "main.footer.t3": "Technical support",
  },

  ru: {
    "main.hero.title":
      "Подходящее место для онлайн-торговли на финансовых рынках",
    "main.hero.subtitle":
      "Самый удобный интерфейс. Получите доступ к торговле более чем 100 мировыми торговыми активами.",
    "main.hero.button": "Начните в один клик",
    "main.conditions.title": "Начинайте свои сделки на лучших условиях",
    "main.conditions.i-1": "Минимальная сумма инвестиций",
    "main.conditions.i-2": "Минимальная сумма сделки",
    "main.conditions.i-3": "Виртуальные деньги на вашем демо-счете",
    "main.conditions.i-4": "Способы оплаты",
    "main.conditions.i-5": "Отсутствие комиссии на ввод и вывод средств",
    "main.conditions.i-6": "Активы для торговли",
    "main.why.title": "Активы для торговли",
    "main.why.i-1_title": "Гибкая торговля",
    "main.why.i-1_text":
      "Последние тенденции: быстрая и цифровая торговля, экспресс-торговля, форекс MT5, отложенные ордера, копирование сделок. Выплаты до 218%.",
    "main.why.i-2_title": "Разнообразные торговые инструменты",
    "main.why.i-2_text":
      "Aнаборы, подходящие для любого трейдера: валюта, сырьевые товары, акции, криптовалюты.",
    "main.why.i-3_title": "Демо счет",
    "main.why.i-3_text":
      "Попробуйте все преимущества платформы на демо-счете, используя виртуальные деньги. Никаких инвестиций, никаких рисков.",
    "main.why.i-4_title": "Легкие депозиты и снятие средств",
    "main.why.i-4_text":
      "Используйте кэшбэк и другие преимущества для более комфортной торговли с минимальными рисками.",
    "main.why.i-5_title": "Торговые преимущества",
    "main.why.i-5_text":
      "Используйте кэшбэк и другие преимущества для более комфортной торговли с минимальными рисками.",
    "main.offer.title": "Проверьте свою удачу!",
    "main.offer.text": `Введите свой адрес электронной почты и получите специальное предложение от ${config.default_title}. Если удача будет на вашей стороне, вы получите подарок!`,
    "main.offer.start": "Начните в один клик",

    "main.about_us": "О нас",
    "main.privacy_policy": "Политика конфиденциальности",
    "main.cookie_policy": "Политика использования файлов Cookie",
    "main.terms_of_use": "Условия использования",
    "main.help_center": "Поддержка",
    "main.faq": "Часто задаваемые вопросы",
    "main.aml_policy": "Политика KYC/AML",

    "main.footer.t1": "Все права защищены.",
    "main.footer.t2": "Общая информация",
    "main.footer.t3": "Техническая поддержка",
  },

  es: {
    "main.hero.title":
      "El lugar adecuado para operar en línea en los mercados financieros",
    "main.hero.subtitle":
      "La interfaz más conveniente para acceder a más de 100 activos de trading globales",
    "main.hero.button": "Comenzar en un clic",
    "main.conditions.title":
      "Realiza tus operaciones en las mejores condiciones",
    "main.conditions.i-1": "Cantidad mínima de inversión",
    "main.conditions.i-2": "Cantidad mínima de operación",
    "main.conditions.i-3": "Dinero virtual en tu cuenta demo",
    "main.conditions.i-4": "Métodos de pago",
    "main.conditions.i-5": "Sin comisión en depósito y retiro",
    "main.conditions.i-6": "Activos para trading",
    "main.why.title": "Activos para trading",
    "main.why.i-1_title": "Trading flexible",
    "main.why.i-1_text":
      "Últimas tendencias: trading rápido y digital, operaciones exprés, forex mt5, órdenes pendientes, copia de operaciones. Pagos de hasta un 218%.",
    "main.why.i-2_title": "Instrumentos de trading diversos",
    "main.why.i-2_text":
      "Activos adecuados para cualquier trader: divisas, materias primas, acciones, criptomonedas.",
    "main.why.i-3_title": "Cuenta demo",
    "main.why.i-3_text":
      "Prueba todos los beneficios de la plataforma en la cuenta demo utilizando dinero virtual. No se necesita inversión, no hay riesgos involucrados.",
    "main.why.i-4_title": "Depósitos y retiros fáciles",
    "main.why.i-4_text":
      "Utiliza el cashback y otras ventajas para una experiencia de trading más cómoda con riesgos mínimos.",
    "main.why.i-5_title": "Ventajas del trading",
    "main.why.i-5_text":
      "Utiliza el cashback y otras ventajas para una experiencia de trading más cómoda con riesgos mínimos.",
    "main.offer.title": "¡Prueba tu suerte!",
    "main.offer.text": `Ingresa tu dirección de correo electrónico y recibe una oferta especial de ${config.default_title}. Si la suerte está de tu lado, ¡recibirás un regalo gratis!`,
    "main.offer.start": "Comenzar en un clic",

    "main.about_us": "Sobre Nosotros",
    "main.privacy_policy": "Política de Privacidad",
    "main.cookie_policy": "Política de Cookies",
    "main.terms_of_use": "Términos de Uso",
    "main.help_center": "Centro de Ayuda",
    "main.faq": "Preguntas Frecuentes",
    "main.aml_policy": "Política KYC/AML",

    "main.footer.t1": "Todos los derechos reservados.",
    "main.footer.t2": "Información general",
    "main.footer.t3": "Soporte técnico",
  },
};

export default main;
