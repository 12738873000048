import { Link } from "react-router-dom";
import moment from "moment";
import config from "../../../config";

import "./footer.sass";
import { useTranslation } from "@helpers/translate";
import {LogoIcon} from "@components/library";
import React from "react";

export const Footer = () => {
  const { __ } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__top">
        <Link className="header__logo" to="/">
          <LogoIcon />
        </Link>

        <div className="footer__text-wrap">
          <p className="footer__text">
            {__("seo.title")} © {moment().year()}. {__("main.footer.t1")}
          </p>
          <p className="footer__text">
            {__("main.footer.t2")}:{" "}
            <a href={`mailto:${config.infoEmail}`}>{config.infoEmail}</a>
          </p>
          <p className="footer__text">
            {__("main.footer.t3")}:{" "}
            <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
          </p>
        </div>
      </div>
      <div className="footer__bottom">
        <nav className="footer__nav">
          <p className='footer__title'>Legal</p>
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/page/privacy-policy" className="footer__link">
                {__("main.privacy_policy")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/terms-and-conditions" className="footer__link">
                {__("main.terms_of_use")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/aml-policy" className="footer__link">
                {__("main.aml_policy")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/cookie-policy" className="footer__link">
                {__("main.cookie_policy")}
              </Link>
            </li>
            {/*<li className="footer__item">*/}
            {/*  <Link to="/page/help-center" className="footer__link">*/}
            {/*    {__("main.help_center")}*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li className="footer__item">*/}
            {/*  <Link to="/page/faq" className="footer__link">*/}
            {/*    {__("main.faq")}*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </nav>
      </div>
    </footer>
  );
};
