import useStyles from "@helpers/theme/useStyles";

export const LoginIcon = () => {
    const styles = useStyles();

    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
            stroke={styles.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}